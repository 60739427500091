
import Api from "@/services/Api";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
// import ModalCreateEditBandeira from "./components/ModalCreateEditBandeira.vue";
import useEmitter from "@/composables/Emmiter";
import ModalEditPlanoLojaVue from "./components/ModalEditPlanoLoja.vue";
import moment from 'moment';

interface LojaPlano {
  codConcessionaria: number | null;
  dataFim: string;
  dataInicio: string;
  id: number | null;
  idPlano: number | null;
  nome: string;
  nomeFantasia: string;
  status: string;
  limiteMensalBuscaPlaca: number | null;
  saldoBuscaPlaca: number | null;
}

export default defineComponent({
  name: "Planos",
  components: {Title, Button, ModalEditPlanoLojaVue },
  props: [],
  setup() {
    const router = useRouter();
    const paramsProdutos: Ref<any> = ref({ pagina: 1, limite: 20, input: '' })
    const emitter = useEmitter()
    const auxModal: Ref<any> = ref(null);
    const lojasSelect: Ref<LojaPlano[]> = ref([]);
    const planosSelect: Ref<any> = ref([]);
    const lojasWithPlans: Ref<LojaPlano[]> = ref([]);
    const lojaPlanoSelected: Ref<LojaPlano> = ref({
        codConcessionaria: null,
        dataFim: '',
        dataInicio: '',
        id: null,
        idPlano: null,
        nome: '',
        nomeFantasia: '',
        status: '',
        limiteMensalBuscaPlaca: null,
        saldoBuscaPlaca: null
    });
    const filtroPlano = ref()
    const filtroLoja = ref()
    const paramsFilter: Ref<LojaPlano> = ref({
        codConcessionaria: null,
        dataFim: '',
        dataInicio: '',
        id: null,
        idPlano: null,
        nome: '',
        nomeFantasia: '',
        status: '',
        limiteMensalBuscaPlaca: null,
        saldoBuscaPlaca: null
    })
    const totalLojasWithPlans: Ref<number> =ref(0)
    const inputFind: Ref<string> = ref('')

    async function getAllPlans(pageParam: number, limite: number, paramsFilter: LojaPlano) {

      const addDate = {dataInicial_01: '',dataInicial_02: '',dataFinal_01: '',dataFinal_02: ''}
      
      if(paramsFilter.dataFim && paramsFilter.dataFim.length){
        addDate.dataFinal_01 = formatarDataParaBanco(paramsFilter.dataFim[0])
        addDate.dataFinal_02 = formatarDataParaBanco(paramsFilter.dataFim[1])
      }
      if(paramsFilter.dataInicio && paramsFilter.dataInicio.length){
        addDate.dataInicial_01 = formatarDataParaBanco(paramsFilter.dataInicio[0])
        addDate.dataInicial_02 = formatarDataParaBanco(paramsFilter.dataInicio[1])
      }

      const novoParamsFilter = { ...paramsFilter, ...addDate };

      try {
        const { data } = await Api.post("getAllPlansPag", {pagina: pageParam, limite, paramsFilter: novoParamsFilter});
        lojasWithPlans.value = data.original.lojasWithPlans;
        totalLojasWithPlans.value = data.original.totalLojasComPlanos;        
      } catch (error) {
        console.log(error);
      }
    }

    async function getAll() {
        try {
            const { data } = await Api.get("getAllPlans");
            lojasSelect.value = data;
        } catch (error) {
        console.log(error);
      }
    }

    async function getAllPlanosLoja() {
        try {
            const { data } = await Api.get("getAllPlanosLoja");
            planosSelect.value = data;
        } catch (error) {
        console.log(error);
      }
    }

    async function clickedLojaPlan(id: number | null) {
      try {
        const {data} = await Api.get('getLojaWithPlans', {idPlanoConcessionaria: id})
        lojaPlanoSelected.value = data   
      } catch (error) {
        console.log(error)
      }
    }

    function usePagination() {
      const state = ref({ currentPage: 1, itemsPerPage: 20, itemsOnPage: 0,});
      const pageCount = computed(() => Math.ceil(totalLojasWithPlans.value / state.value.itemsPerPage));
      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return lojasWithPlans.value && lojasWithPlans.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {state,setCurrentPage,pageCount,itemsOnPage}
    }

    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };
    
    function closeModal(): void {
      auxModal.value.hide()
      auxModal.value = null
      getAllPlans(state.value.currentPage, state.value.itemsPerPage, paramsFilter.value)
    }

    emitter.on("paramsFilter", (valor: any) => {
      paramsFilter.value = valor.paramsFilter
    })

    function formatarDataParaBanco(data: any) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss');
    } 

    function formatarDataParaTemplate(data: any) {
      return moment(data).format('DD/MM/YYYY');
    } 
    
    watch(
      () => state.value.currentPage,
      () => getAllPlans(state.value.currentPage, state.value.itemsPerPage, paramsFilter.value)
    )
    
    watch(
      () => paramsFilter.value,
      () => {
            getAllPlans(state.value.currentPage, state.value.itemsPerPage, paramsFilter.value)
      }, {deep: true}
    )
    
    onMounted(() => {getAllPlans(state.value.currentPage, state.value.itemsPerPage, paramsFilter.value), getAll(), getAllPlanosLoja()})
    
    return { lojasWithPlans, lojasSelect, paramsFilter, filtroLoja, planosSelect, filtroPlano, formatarDataParaTemplate, clickedLojaPlan, state, setCurrentPage, pageCount, itemsOnPage, totalLojasWithPlans, inputFind, openModal, closeModal, lojaPlanoSelected , getAllPlans};
    
  },
});
