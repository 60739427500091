
interface LojaPlano {
  codConcessionaria: number | null;
  dataFim: string;
  diaVencimento: number;
  dataInicio: string;
  id: number | null;
  idPlano: number | null;
  nome: string;
  nomeFantasia: string;
  status: string;
  limiteMensalBuscaPlaca: number | null;
  saldoBuscaPlaca: number | null;
}

import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { defineComponent, PropType, Ref, ref, watch } from "vue";
import Button from "@/components/Button.vue";
import moment from 'moment';
import Api from "@/services/Api";
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "ModalEditPlanoLoja",
  components: { Button, ElConfigProvider },
  emits: [],
  props: {lojaPlanoSelected: Object as PropType<LojaPlano>, closeModal: Function, get: Function},

  setup(props) {
    const { showTimeAlert } = useAlert();

    const lojaWithPlanUpdated: Ref<LojaPlano> = ref({
        codConcessionaria: null,
        dataFim: '',
        dataInicio: '',
        diaVencimento: 0,
        id: null,
        idPlano: null,
        nome: '',
        nomeFantasia: '',
        status: '',
        limiteMensalBuscaPlaca: null,
        saldoBuscaPlaca: null,
    })

    async function UpdatePlan(e:Event) {
        e.preventDefault()
        try {
            const {dataFim} = lojaWithPlanUpdated.value
            const dataFimFormatada = formatarDataParaBanco(lojaWithPlanUpdated.value.dataFim)
            const {data} = await Api.post('updatePlan', {...lojaWithPlanUpdated.value, dataFim: dataFimFormatada})
            if(data){
                props.closeModal && props.closeModal()
                showTimeAlert('Plano atualizado com sucesso!', "success")
            }
        } catch (error) {
            console.log(error)
        }
    }

    function formatarDataParaBanco(data) {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
    }

    watch(() => props.lojaPlanoSelected, () => lojaWithPlanUpdated.value = props.lojaPlanoSelected as any)


    return {ptBr, UpdatePlan, lojaWithPlanUpdated, formatarDataParaBanco};
  },
});
